import $ from 'jquery';
import 'jquery-plainmodal';

let $modal = null;

export const showModalError = (message) => {
  // eslint-disable-line import/prefer-default-export
  $modal = $(
    '<div class="modal-card bg-light col-xs-6" style="text-align: center;">' + // eslint-disable-line
      '<div class="card-body">'
      + '<p class="lead" style="color:black; font-weight:400">'
      + message
      + '</p>'
      + '<div><button class="btn btn-secondary btn-lg plainmodal-close" style="font-weight:400; padding-left: 40px; padding-right: 40px;">OK</button></div>'
      + '</div></div>',
  );
  $modal.plainModal('open');
};

export const showCreatePaymentLinkModal = () => {
  // Select the modal element
  const modal = document.querySelector('.failed-ota-overlay');
  if (!modal) {
    return;
  }

  // Set the default message in the modal's custom message field
  const messageInput = modal.querySelector('[name="merchant_custom_message"]');
  if (messageInput) {
    messageInput.value = 'We tried to take a payment using the payment card you provided for your booking, but it was not successful. You can still confirm your booking by paying using the link below';
  }

  const yesRadioButton = modal.querySelector(
    'input[name="auto_send_to_customer"][value="y"]',
  );
  if (yesRadioButton) {
    yesRadioButton.checked = true;
    // Trigger change event to show/hide dependent elements
    $(yesRadioButton).trigger('change');
  }

  // Make the modal visible by removing toggling off the hidden class
  modal.classList.toggle('hidden', false);

  // Select skip and close buttons
  const skipButton = modal.querySelector('#skip-btn');
  const closeButton = modal.querySelector('#close-btn');

  if (!skipButton || !closeButton) {
    return;
  }

  // Function to close the modal
  function closeModal() {
    $('input[name="auto_send_to_customer"][value=""]')
      .prop('checked', true)
      .trigger('change');
    modal.classList.toggle('hidden', true);
    $('[name="merchant_custom_message"]').val('');
  }

  // Attach event listeners to close and skip buttons
  closeButton.addEventListener('click', closeModal);
  skipButton.addEventListener('click', closeModal);

  // Event listener for clicking outside the modal content
  modal.addEventListener('click', (event) => {
    if (event.target === modal) {
      closeModal();
    }
  });

  // Close the modal when the Escape key is pressed
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  });
};
